import { React, useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Typography,
  Select,
  DatePicker,
  Upload,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { HttpHandlerContext } from '../../shared/MsalHttpHandler';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
const { Paragraph } = Typography;

const departmentMap = {
  // 0: '全部',
  1: 'iRent',
  // 2: '門市',
  // 3: '專車',
  // 4: '停車場',
};
const typeMap = {
  1: '公告',
  2: '品牌活動',
  3: '純活動曝光',
  4: '異業合作',
  5: '新服務/機制推廣',
  6: '會員拉新活動',
  7: '會員經營活動',
  8: '營運分類',
};
const statusMap = {
  1: '尚未開始',
  2: '進行中',
  98: '已駁回',
  99: '已結案',
};
let eventMap = [];
let customer_list = [];
let test_list = [];

// 父組件
const MarketingCampaign = () => {
  const httpHandler = useContext(HttpHandlerContext);
  const [searchForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [customerForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pickid, setPickid] = useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCustomerVisible, setIsCustomerVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    getallevent();
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    const { name, department } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);

    httpHandler
      .get(
        `/marketing_campaign/list?ids=${pickid}&name=${name}&department=${department}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchMarketingCampaignItem = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
    getallevent();
  };

  useEffect(() => {
    // 預設搜尋條件
    searchForm.setFieldsValue({
      name: '',
      department: '1',
    });
    modalForm.setFieldsValue({
      department: '1',
    });
    // 進入畫面時執行一次搜尋
    searchMarketingCampaignItem();
  }, []);

  const getallevent = () => {
    const { current, pageSize } = tableParams.pagination;

    httpHandler
      .get(
        `/marketing_campaign/list?ids=-1&name=&department=0&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        const names = response.data.map((item) => item.name);
        const ids = response.data.map((item) => item.id);
        eventMap = names.reduce((acc, name, index) => {
          acc[ids[index]] = name;
          return acc;
        }, {});
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  // 搜尋組件
  const SearchBar = () => {
    const [options, setOptions] = useState(Object.keys(eventMap));
    const [latest, setLatest] = useState('');

    const handleSearch = (input) => {
      // 動態取得當前選單
      const filteredOptions = Object.keys(eventMap).filter((key) =>
        eventMap[key].toLowerCase().includes(input.toLowerCase())
      );
      setLatest(input);
      setOptions(filteredOptions);
    };

    const nameChange = (value) => {
      if (value === undefined) {
        setPickid(-1);
        searchForm.setFieldsValue({ name: '' });
      } else {
        setPickid(value);
        searchForm.setFieldsValue({ name: eventMap[value] });
      }
    };

    const handleBlur = () => {
      const values = searchForm.getFieldsValue();

      // 選單長度不為0 && 當前輸入框有文字，失焦時自動選擇選單的第一個選項
      if (options.length !== 0 && latest !== '') {
        setPickid(options[options.length - 1]);
        searchForm.setFieldsValue({
          name: eventMap[options[options.length - 1]],
        });
      }
      // 如果沒有選擇選項 && 選單長度不為0 && 當前輸入框沒有文字
      else if (
        (values.name === '' && options.length !== 0 && latest === '') ||
        // 選單長度為0 && 當前輸入框有文字
        (options.length === 0 && latest !== '')
      ) {
        setPickid(-1);
        searchForm.setFieldsValue({ name: '' });
      }
    };

    const DownloadSample = () => {
      Modal.confirm({
        maskClosable: true, // 點擊蒙層是否允許關閉
        title: '確定下載範例？',
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn loading={loading} />
          </>
        ),
        okText: '確定',
        cancelText: '取消',
        onOk: async () => {
          httpHandler
            .fileDownload(
              `/marketing_campaign/download_customer_sample`,
              'csv',
              '客群名單範例'
            )
            .then((response) => {
              console.log(response.message);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        },
      });
    };

    return (
      <>
        <Form
          form={searchForm}
          name="searchForm"
          onFinish={searchMarketingCampaignItem}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="活動名稱" name="name">
                <Select
                  showSearch
                  allowClear
                  onChange={nameChange}
                  onBlur={handleBlur}
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {options
                    .slice()
                    .reverse()
                    .map((key) => (
                      <Select.Option key={key} value={key}>
                        {eventMap[key]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="業務別" name="department">
                <Select>
                  {Object.keys(departmentMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {departmentMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<VerticalAlignBottomOutlined />}
                style={{ marginRight: 5 }}
                onClick={() => {
                  DownloadSample();
                }}>
                下載客群範例
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsModalVisible(true);
                }}
                className="button button--register">
                新增
              </Button>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search"
                onClick={() => {
                  updateData();
                }}>
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  // 表格組件
  const DataTable = () => {
    const [isReadMoreModalVisible, setIsReadMoreModalVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const readMore = (text) => {
      const maxLength = 10; // 最大顯示的字元數
      if (text.length > maxLength) {
        return (
          <span>
            {text.substring(0, maxLength)}...
            <a
              onClick={() => {
                setCurrentText(text);
                setIsReadMoreModalVisible(true);
              }}>
              閱讀更多
            </a>
          </span>
        );
      }
      return text;
    };
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '活動名稱',
        dataIndex: 'name',
      },
      {
        title: '操作',
        dataIndex: '',
        render: (text, record, index) => {
          return (
            <>
              <Button
                icon={<EditOutlined />}
                type="primary"
                className="button--edit"
                style={{ marginRight: 16 }}
                disabled={record.status === '2'}
                onClick={async () => {
                  customer_list = await JSON.parse(record.customer_name_list);
                  setIsModalVisible(true);
                  setIsNew(false);
                  modalForm.setFieldsValue({
                    id: record.id,
                    name: record.name,
                    department: `${record.department}`,
                    type: `${record.type}`,
                    description: `${record.description}`,
                    startDate: dayjs(record.start_date),
                    endDate: dayjs(record.end_date),
                    closeDate: dayjs(record.close_date),
                  });
                }}>
                修改
              </Button>
            </>
          );
        },
      },
      {
        title: '活動描述',
        dataIndex: 'description',
        render: (text) => {
          if (text) {
            return readMore(text);
          }
        },
      },
      {
        title: '業務別',
        dataIndex: 'department',
        render: (text) => {
          return departmentMap[text];
        },
      },
      {
        title: '活動類型',
        dataIndex: 'type',
        render: (text) => {
          return typeMap[text];
        },
      },
      {
        title: '狀態',
        dataIndex: 'status',
        render: (text) => {
          return statusMap[text];
        },
      },
      {
        title: '開始日期',
        dataIndex: 'start_date',
      },
      {
        title: '結束日期',
        dataIndex: 'end_date',
      },
      {
        title: '結案日期',
        dataIndex: 'close_date',
      },
      {
        title: '修改時間',
        dataIndex: 'update_time',
      },
      {
        title: '修改人員',
        dataIndex: 'update_user',
      },
      {
        title: '建立時間',
        dataIndex: 'create_time',
      },
      {
        title: '建立人員',
        dataIndex: 'create_user',
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title="完整內容"
          open={isReadMoreModalVisible}
          onOk={() => setIsReadMoreModalVisible(false)}
          onCancel={() => setIsReadMoreModalVisible(false)}>
          <Typography>
            <Paragraph>
              <pre>{currentText}</pre>
            </Paragraph>
          </Typography>
        </Modal>
      </>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <MarketingCampaignModal
          searchMarketingCampaignItem={searchMarketingCampaignItem}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
          setIsCustomerVisible={setIsCustomerVisible}
          customerForm={customerForm}
        />
      ),
    },
    {
      hasTitle: false,
      title: 'CustomerModal',
      children: (
        <CustomerModal
          searchMarketingCampaignItem={searchMarketingCampaignItem}
          isCustomerVisible={isCustomerVisible}
          setIsCustomerVisible={setIsCustomerVisible}
          customerForm={customerForm}
          modalForm={modalForm}
        />
      ),
    },
  ];

  return (
    <div>
      <PageHeader functionName={'marketing_campaign'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

// 將時、分、秒都設定為0
const toExactDate = (date) => {
  const d = new Date(date);
  d.setHours(8, 0, 0, 0); // Set hours, minutes, seconds and milliseconds to 0
  return d;
};

// 共用的Modal
const MarketingCampaignModal = (props) => {
  const httpHandler = useContext(HttpHandlerContext);
  const {
    searchMarketingCampaignItem,
    isModalVisible,
    setIsModalVisible,
    modalForm,
    isNew,
    setIsCustomerVisible,
    customerForm,
  } = props;

  const [loading, setLoading] = useState(false);

  const handlerequest = async () => {
    const values = await modalForm.getFieldsValue();
    const formData = new FormData();

    await httpHandler
      .get(
        `/marketing_campaign/list?ids=-1&name=&department=0&page=1&page_size=10&is_all=false`
      )
      .then((response) => {
        const names = response.data.map((item) => item.name);
        const ids = response.data.map((item) => item.id);
        eventMap = names.reduce((acc, name, index) => {
          acc[ids[index]] = name;
          return acc;
        }, {});
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    const keys = Object.keys(eventMap);

    // 取得最後一個元素的 key
    const lastKey = keys[keys.length - 1];

    formData.append('ids', lastKey);
    test_list.forEach((customer, index) => {
      formData.append(`${index}_name`, customer.customer_name);
      if (customer.description) {
        formData.append(`${index}_description`, customer.description);
      }
      formData.append(`${index}_file`, customer.target_customer);
    });

    await httpHandler
      .post(`/target_customer/add_customer_with_campaign`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data; charset=UTF-8',
        },
      })
      .then((response) => {
        if (response.success) {
          window.antdNotify('成功', '新增活動＆客群成功', 'success');
          setIsCustomerVisible(false);
          setLoading(false);
        } else {
          setLoading(false);
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .finally(() => {
        searchMarketingCampaignItem();
        setLoading(false);
      });
  };
  const handleModalSubmit = async () => {
    try {
      const values = await modalForm.validateFields();
      const data = await customerForm.getFieldsValue();
      const confirmed = await window.antdConfirm(
        `確定${isNew ? '新增' : '修改'}活動嗎？`
      );
      if (!confirmed) return;
      setLoading(true);
      // 根據isNew判斷是新增還是修改
      let requestPromise;
      const msg = isNew ? '新增成功' : '修改成功';

      let postData = {
        name: values.name,
        department: values.department,
        description: values.description,
        type: values.type,
        start_date: toExactDate(values.startDate).toISOString(),
        end_date: toExactDate(values.endDate).toISOString(),
        close_date: toExactDate(values.closeDate).toISOString(),
      };

      if (isNew === true) {
        if (test_list.length > 0) {
          requestPromise = httpHandler.post('/marketing_campaign/', postData);

          requestPromise
            .then(async (response) => {
              if (response.success) {
                await handlerequest();
                setIsModalVisible(false);
                setLoading(false);
              } else {
                setLoading(false);
                window.antdNotify('錯誤', response.message, 'fail');
              }
            })
            .finally(() => {
              test_list = [];
              customer_list = [];
              modalForm.resetFields();
              customerForm.resetFields();
              setLoading(false);
              modalForm.setFieldsValue({
                department: '1',
              });
            });
        } else {
          requestPromise = httpHandler.post('/marketing_campaign/', postData);

          requestPromise
            .then(async (response) => {
              if (response.success) {
                setIsModalVisible(false);
                setLoading(false);
                window.antdNotify('成功', response.message, 'success');
              } else {
                setLoading(false);
                window.antdNotify('錯誤', response.message, 'fail');
              }
            })
            .finally(() => {
              test_list = [];
              customer_list = [];
              modalForm.resetFields();
              customerForm.resetFields();
              setLoading(false);
              searchMarketingCampaignItem();
              modalForm.setFieldsValue({
                department: '1',
              });
            });
        }
      } else {
        requestPromise = httpHandler.put(
          `/marketing_campaign/${values.id}`,
          postData
        );
        requestPromise
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', msg, 'success');
              setIsModalVisible(false);
              searchMarketingCampaignItem();
              modalForm.resetFields();
              customerForm.resetFields();
              setLoading(false);
            } else {
              setLoading(false);
              window.antdNotify('錯誤', response.message, 'fail');
            }
          })
          .finally(() => {
            setLoading(false);
            modalForm.setFieldsValue({
              department: '1',
            });
          });
      }
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = () => {
    customer_list = [];
    modalForm.resetFields();
    customerForm.resetFields();
    setIsModalVisible(false);
    modalForm.setFieldsValue({
      department: '1',
    });
  };
  return (
    <Modal
      title={isNew ? '新增活動' : '修改活動'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}
      okButtonProps={{ loading: loading }}>
      <ModalForm
        modalForm={modalForm}
        isNew={isNew}
        setIsCustomerVisible={setIsCustomerVisible}
      />
    </Modal>
  );
};

const CustomerModal = (props) => {
  const {
    searchMarketingCampaignItem,
    isCustomerVisible,
    setIsCustomerVisible,
    customerForm,
    modalForm,
  } = props;
  const [loading, setLoading] = useState(false);
  const httpHandler = useContext(HttpHandlerContext);
  const handleModalSubmit = async () => {
    try {
      const data = await customerForm.validateFields();
      const values = await modalForm.getFieldsValue();
      let requestPromise;
      const formData = new FormData();

      formData.append('ids', -1);
      formData.append('customer_name', data.customer_name);
      if (
        data.description !== null &&
        data.description !== '' &&
        data.description !== undefined
      ) {
        formData.append('description', data.description);
      }
      formData.append('file', data.target_customer.file);

      const test_dict = {
        customer_name: data.customer_name,
        target_customer: data.target_customer.file,
      };
      if (
        data.description !== null &&
        data.description !== '' &&
        data.description !== undefined
      ) {
        test_dict['description'] = data.description;
      }
      test_list.push(test_dict);

      customer_list.push(data.customer_name);
      customerForm.resetFields();
      setIsCustomerVisible(false);
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = () => {
    customerForm.resetFields();
    setIsCustomerVisible(false);
  };
  return (
    <Modal
      title={'新增客群'}
      open={isCustomerVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}
      okButtonProps={{ loading: loading }}>
      <CustomerForm customerForm={customerForm} />
    </Modal>
  );
};

const CustomerForm = (props) => {
  const { customerForm } = props;
  const fileList = useRef(null);

  const handleChange = (file) => {
    fileList.current = file;
    if (fileList.current.fileList.length === 0) {
      fileList.current = null;
      customerForm.validateFields(['target_customer']);
    }
  };

  const beforeUpload = (file) => {
    file = file['file']['originFileObj'];
    return file;
  };

  return (
    <Form form={customerForm}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="客群名稱"
        name="customer_name"
        rules={[{ required: true, message: '請選擇客群名稱!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="客群描述" name="description" labelCol={{ span: 4 }}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="上傳客群"
        name="target_customer"
        rules={[{ required: true, message: '請上傳客群' }]}>
        <Upload
          accept=".csv"
          beforeUpload={beforeUpload}
          ref={fileList}
          onChange={handleChange}
          maxCount={1}>
          <Button icon={<PlusOutlined />}>上傳</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
};

// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm, isNew, setIsCustomerVisible } = props;

  return (
    <Form
      form={modalForm}
      initialValues={{
        startDate: dayjs(),
        // 結束日期預設為一個月後
        endDate: dayjs().add(1, 'month'),
        // 預設日期預設為三個月後
        closeDate: dayjs().add(3, 'month'),
      }}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="活動名稱"
        name="name"
        rules={[{ required: true, message: '請輸入活動名稱' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="業務別"
        name="department"
        rules={[{ required: true, message: '請選擇業務別' }]}>
        <Select>
          {Object.keys(departmentMap)
            // 僅先開放iRent業務別
            // .filter((key) => key !== '0')
            .filter((key) => key === '1')
            .map((key) => (
              <Select.Option key={key} value={key}>
                {departmentMap[key]}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="活動類型"
        name="type"
        rules={[{ required: true, message: '請選擇活動類型' }]}>
        <Select>
          {Object.keys(typeMap).map((key) => (
            <Select.Option key={key} value={key}>
              {typeMap[key]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="開始日期"
        name="startDate"
        rules={[{ required: true, message: '請選取開始日期' }]}>
        <DatePicker
          disabledDate={(current) =>
            current && current.isBefore(dayjs().startOf('day'))
          }
          onChange={(value) => {
            const data = modalForm.getFieldsValue();

            if (value.isAfter(data.endDate, 'day')) {
              modalForm.setFieldsValue({ endDate: value });
            }
            if (value.isAfter(data.closeDate, 'day')) {
              modalForm.setFieldsValue({ closeDate: value });
            }
            modalForm.validateFields(['endDate']);
            modalForm.validateFields(['closeDate']);
          }}
        />
      </Form.Item>
      <Form.Item
        label="結束日期"
        name="endDate"
        rules={[{ required: true, message: '請選取結束日期' }]}>
        {/* 結束日期只能選取當前日期之前 */}
        <DatePicker
          disabledDate={(current) => {
            // Get startDate from form
            const startDate = modalForm.getFieldValue('startDate');
            // Disable endDate before startDate
            return (
              current &&
              (current.isBefore(dayjs().startOf('day')) ||
                current.isBefore(startDate, 'day'))
            );
          }}
        />
      </Form.Item>
      <Form.Item
        label="結案日期"
        name="closeDate"
        rules={[{ required: true, message: '請選取結案日期' }]}>
        {/* 結束日期只能選取當前日期之前 */}
        <DatePicker
          disabledDate={(current) => {
            // Get startDate from form
            const startDate = modalForm.getFieldValue('startDate');
            // Disable endDate before startDate
            return (
              current &&
              (current.isBefore(dayjs().startOf('day')) ||
                current.isBefore(startDate, 'day'))
            );
          }}
        />
      </Form.Item>
      <Form.Item
        label="活動描述"
        name="description"
        rules={[{ required: true, message: '請輸入活動描述' }]}>
        <Input.TextArea />
      </Form.Item>
      {isNew && (
        <>
          <Form.Item label="新增客群資料" name="target_customer">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCustomerVisible(true);
              }}
              className="button button--register">
              新增
            </Button>
          </Form.Item>
        </>
      )}
      <Form.Item label="已新增客群" name="customers_list">
        <div>{customer_list.join('、')}</div>
      </Form.Item>
    </Form>
  );
};

// 驗證props
MarketingCampaignModal.propTypes = {
  searchMarketingCampaignItem: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
  customerForm: PropTypes.object,
};
CustomerModal.propTypes = {
  searchMarketingCampaignItem: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
  customerForm: PropTypes.object,
};
ModalForm.propTypes = {
  modalForm: PropTypes.object,
};
CustomerForm.propTypes = {
  customerForm: PropTypes.object,
};
export default MarketingCampaign;
