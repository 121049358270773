import { React, useContext, useState, useEffect, useRef } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Typography,
  Select,
  Upload,
  Checkbox,
  DatePicker,
  Radio,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  SignatureOutlined,
  UndoOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { HttpHandlerContext } from '../../shared/MsalHttpHandler';
import en from 'antd/es/date-picker/locale/en_US';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Header } from 'antd/es/layout/layout';
import { render } from '@testing-library/react';
import { json } from 'react-router-dom';

const { Paragraph } = Typography;

const itemTypeMap = {
  0: '已上傳',
  1: '未上傳',
};
const typeMap = {
  0: '全部',
  1: '公告',
  2: '品牌活動',
  3: '純活動曝光',
  4: '異業合作',
  5: '新服務/機制推廣',
  6: '會員拉新活動',
  7: '會員經營活動',
  8: '營運分類',
};
const departmentMap = {
  0: '全部',
  1: 'iRent',
  2: '門市',
  3: '專車',
  4: '停車場',
};
let eventMap = {};
let eventStatus = {};
const saleventMap = {
  0: '10001',
  1: '10002',
  2: '10003',
};
const channelMap = {
  0: '全部',
  1: '推播',
  2: 'EDM',
  3: 'SMS',
};
const statusMap = {
  1: '未送審',
  2: '送審中',
  97: '已撤銷',
  98: '已駁回',
  99: '已核可',
};
const scoreMap = ['50分以下', '50-60', '60分以上'];
let customer_list = [];
let customer_dict = {};
// 父組件
const CampaignProject = () => {
  const httpHandler = useContext(HttpHandlerContext);
  const [searchForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [modifyStatusForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pickid, setPickid] = useState(-1);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModifyStatusModalVisible, setIsModifyStatusModalVisible] =
    useState(false);
  const [isNew, setIsNew] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [testButtonLoading, setTestButtonLoading] = useState(false);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    const { name, department, type } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);
    let searchstr = ``;
    if (name === '') {
      searchstr = `/campaign_project/list?ids=-1&name=${name}&department=${department}&type=${type}&page=${current}&page_size=${pageSize}&is_all=false`;
    } else {
      searchstr = `/campaign_project/list?ids=${pickid}&name=${name}&department=${department}&type=${type}&page=${current}&page_size=${pageSize}&is_all=false`;
    }
    httpHandler
      .get(searchstr)
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '確定刪除？',
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn loading={loading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        setLoading(true);
        return httpHandler
          .delete(`/campaign_project/${id}`)
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', '刪除成功', 'success');
            } else {
              window.antdNotify('錯誤', response.message, 'fail');
            }
            updateData();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const sendtest = (id) => {
    // 打API取得該方案測試筆數
    httpHandler
      .get(`/campaign_project/check_test_count/${id}`)
      .then((response) => {
        if (response.success) {
          Modal.confirm({
            maskClosable: true, // 點擊蒙層是否允許關閉
            title: `測試名單數量: ${response.total}，確定發送測試訊息？`,
            icon: <QuestionCircleOutlined />,
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn loading={loading} />
              </>
            ),
            okText: '確定',
            cancelText: '取消',
            onOk: async () => {
              setLoading(true);
              return httpHandler
                .post(`/campaign_project/send_test/${id}`)
                .then((response) => {
                  if (response.success) {
                    window.antdNotify('成功', '發送成功', 'success');
                  } else {
                    window.antdNotify('錯誤', response.message, 'fail');
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
                  setLoading(false);
                  setTestButtonLoading(false);
                });
            },
            onCancel: () => {
              setTestButtonLoading(false);
            },
          });
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      });
  };

  const searchCampaignProjectItem = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
    getallevent();
  };

  useEffect(() => {
    // 預設表單值
    searchForm.setFieldsValue({
      name: '',
      itemType: '0',
      department: '0',
      type: '0',
    });
    // 進入畫面時執行一次搜尋
    searchCampaignProjectItem();
  }, []);

  const getallevent = () => {
    const { current, pageSize } = tableParams.pagination;

    httpHandler
      .get(
        `/marketing_campaign/list?ids=-1&name=&department=0&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        const names = response.data.map((item) => item.name);
        const status = response.data.map((item) => item.status);
        const ids = response.data.map((item) => item.id);
        eventMap = names.reduce((acc, name, index) => {
          acc[ids[index]] = name;
          return acc;
        }, {});

        eventStatus = status.reduce((acc, s, index) => {
          acc[ids[index]] = s;
          return acc;
        }, {});
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  // 搜尋組件
  const SearchBar = () => {
    const [options, setOptions] = useState(Object.keys(eventMap));
    const [latest, setLatest] = useState('');

    const handleSearch = (input) => {
      // 動態取得當前選單
      const filteredOptions = Object.keys(eventMap).filter((key) =>
        eventMap[key].toLowerCase().includes(input.toLowerCase())
      );
      setLatest(input);
      setOptions(filteredOptions);
    };

    const nameChange = (value) => {
      if (value === undefined) {
        setPickid(-1);
        searchForm.setFieldsValue({ name: '' });
      } else {
        setPickid(value);
        searchForm.setFieldsValue({ name: eventMap[value] });
      }
    };

    const handleBlur = () => {
      const values = searchForm.getFieldsValue();

      // 選單長度不為0 && 當前輸入框有文字，失焦時自動選擇選單的第一個選項
      if (options.length !== 0 && latest !== '') {
        setPickid(options[options.length - 1]);
        searchForm.setFieldsValue({
          name: eventMap[options[options.length - 1]],
        });
      }
      // 如果沒有選擇選項 && 選單長度不為0 && 當前輸入框沒有文字
      else if (
        (values.name === '' && options.length !== 0 && latest === '') ||
        // 選單長度為0 && 當前輸入框有文字
        (options.length === 0 && latest !== '')
      ) {
        setPickid(-1);
        searchForm.setFieldsValue({ name: '' });
      }
    };

    const DownloadSample = () => {
      Modal.confirm({
        maskClosable: true, // 點擊蒙層是否允許關閉
        title: '確定下載範例？',
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn loading={loading} />
          </>
        ),
        okText: '確定',
        cancelText: '取消',
        onOk: async () => {
          httpHandler
            .fileDownload(
              `/marketing_campaign/download_customer_sample`,
              'csv',
              '測試人員名單範例'
            )
            .then((response) => {
              console.log(response.message);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        },
      });
    };

    return (
      <>
        <Form
          form={searchForm}
          name="searchForm"
          onFinish={searchCampaignProjectItem}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="活動名稱" name="name">
                <Select
                  showSearch
                  allowClear
                  onChange={nameChange}
                  onBlur={handleBlur}
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {options
                    .slice()
                    .reverse()
                    .map((key) => (
                      <Select.Option key={key} value={key}>
                        {eventMap[key]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="業務別" name="department">
                <Select>
                  {Object.keys(departmentMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {departmentMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="活動類型" name="type">
                <Select>
                  {Object.keys(typeMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {typeMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<VerticalAlignBottomOutlined />}
                style={{ marginRight: 5 }}
                onClick={() => {
                  DownloadSample();
                }}>
                測試人員範例
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsModalVisible(true);
                }}
                className="button button--register">
                新增
              </Button>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search"
                onClick={() => {
                  updateData();
                }}>
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  // 表格組件
  const DataTable = () => {
    const [isReadMoreModalVisible, setIsReadMoreModalVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');

    const readMore = (text) => {
      const maxLength = 10; // 最大顯示的字元數
      if (text.length > maxLength) {
        return (
          <span>
            {text.substring(0, maxLength)}...
            <a
              onClick={() => {
                setCurrentText(text);
                setIsReadMoreModalVisible(true);
              }}>
              閱讀更多
            </a>
          </span>
        );
      }
      return text;
    };

    // 取得當前活動後的所有客群(修改方案使用)
    const getCustomers = async (record) => {
      customer_list = [];
      await httpHandler
        .get(
          `/target_customer/list?ids=${pickid}&name=${record.name}&page=1&page_size=10&is_all=false&fuzzy=false`
        )
        .then((response) => {
          if (response.success) {
            customer_list = [];
            customer_dict = {};
            response.data.forEach((element) => {
              customer_list.push(element.customer_name);
              customer_dict[element.id] = element.customer_name;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      setList(customer_list);
    };

    const RenderButtons = (
      record,
      channelType,
      channelContent,
      create_day,
      send_day
    ) => {
      return (
        <>
          <Button
            icon={<EditOutlined />}
            type="primary"
            className="button--edit"
            loading={loading}
            disabled={
              record.status == '2' ||
              record.status == '99' ||
              record.status == '97'
            }
            style={{ marginRight: 16, marginBottom: 4, width: 110 }}
            onClick={() => {
              setIsModalVisible(true);
              setIsNew(false);
              getCustomers(record);
              setPickid(record.campaign_id);
              modalForm.setFieldsValue({
                id: record.id,
                name: record.name,
                project_name: record.project_name,
                campaign_id: record.campaign_id,
                description: record.description,
                channel: `${record.channel}`,
                [channelType]: record[channelType],
                [channelContent]: record[channelContent],
                send_time:
                  record.channel !== 2
                    ? dayjs(record.send_time).add(8, 'hour')
                    : dayjs().add(7, 'day'),
                send_type: send_day === create_day ? '立即發送' : '預約發送',
                customer: JSON.parse(record.customer_id_list),
                push_score: JSON.parse(record.push_score_condition),
                exclusion_rule: JSON.parse(record.exclusion_rule),
              });
            }}>
            修改
          </Button>
          <Button
            icon={<SendOutlined />}
            type="primary"
            className="button--edit"
            loading={testButtonLoading}
            style={{ marginRight: 8, marginBottom: 4, width: 110 }}
            onClick={() => {
              setTestButtonLoading(true);
              sendtest(record.id);
            }}>
            測試
          </Button>

          {(record.status == 1 || record.status == 98) && (
            <Button
              icon={<SignatureOutlined />}
              type="primary"
              className="button--edit"
              style={{ marginRight: 8, width: 110, marginBottom: 4 }}
              onClick={() => {
                modifyStatusForm.setFieldsValue({
                  projectId: record.id,
                  originStatus: record.status,
                  modifyStatus: 2,
                });
                setIsModifyStatusModalVisible(true);
              }}>
              申請簽核
            </Button>
          )}
          {record.status == 98 && (
            <Button
              icon={<UndoOutlined />}
              type="primary"
              className="button--edit"
              style={{ marginRight: 8, width: 110, marginBottom: 4 }}
              onClick={() => {
                modifyStatusForm.setFieldsValue({
                  projectId: record.id,
                  originStatus: record.status,
                  modifyStatus: 97,
                });
                setIsModifyStatusModalVisible(true);
              }}>
              撤銷
            </Button>
          )}
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            className="button--delete"
            disabled={record.status == '2' || record.status == '99'}
            style={{ marginRight: 8, width: 110 }}
            onClick={() => {
              handleDelete(record.id);
            }}>
            刪除
          </Button>
        </>
      );
    };

    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '活動名稱',
        dataIndex: 'name',
        render: (text) => {
          if (text) {
            return readMore(text);
          }
        },
      },
      {
        title: '方案名稱',
        dataIndex: 'project_name',
      },
      {
        title: '方案描述',
        dataIndex: 'description',
        render: (text) => {
          if (text) {
            return readMore(text);
          }
        },
      },
      {
        title: '業務別',
        dataIndex: 'department',
        render: (text) => {
          return departmentMap[text];
        },
      },
      {
        title: '操作',
        dataIndex: '',
        width: 100,
        render: (text, record, index) => {
          const create_day = dayjs(
            record.create_time,
            'YYYY/M/D h:mm:ss'
          ).date();
          const send_day = new Date(record.send_time).getDate();

          const channelType =
            record.channel == 1 ? 'push_title' : 'edm_list_id';
          const channelContent =
            record.channel == 1
              ? 'push_content'
              : record.channel == 2
              ? ''
              : 'sms_content';
          return RenderButtons(
            record,
            channelType,
            channelContent,
            create_day,
            send_day
          );
        },
      },
      {
        title: '客群名稱',
        dataIndex: '',
        render: (record) => {
          const customerList = JSON.parse(record.customer_id_list);
          return (
            <div>
              {customerList.map((customer, index) => (
                <li key={index}>
                  {index + 1}. {customer}
                </li>
              ))}
            </div>
          );
        },
      },
      {
        title: '方案狀態',
        dataIndex: 'status',
        width: 90,
        render: (text) => {
          return statusMap[text];
        },
      },
      {
        title: '行銷通路',
        dataIndex: 'channel',
        width: 70,
        render: (text, record) => {
          return channelMap[text];
        },
      },
      {
        title: '推播標題 / EDM編號',
        dataIndex: '', // 使用 channel 的值來決定 dataIndex
        width: 100,
        render: (text, record) => {
          return record.channel == 1 ? record.push_title : record.edm_list_id;
        },
      },
      {
        title: '推播 / SMS內容',
        dataIndex: '',
        render: (text, record) => {
          text =
            record.channel == 1
              ? record.push_content
              : record.channel == 2
              ? ''
              : record.sms_content;
          if (text) {
            return readMore(text);
          }
        },
      },
      {
        title: '發送日期',
        dataIndex: 'send_time',
        render: (dateString) => {
          if (dateString !== null) {
            const date = new Date(dateString);

            // 獲取年月日和時間
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // 月份從 0 開始，所以要加 1
            const day = date.getDate();
            const hours = date.getHours() + 8;
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            // 確定上午或下午
            const period = hours >= 12 ? '下午' : '上午';
            // 轉換24小時制為12小時制
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

            // 格式化時間
            const formattedDate = `${year}/${month}/${day} ${period}${formattedHours}:${minutes
              .toString()
              .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

            return formattedDate;
          }
          // EDM不會有發送日期
          else {
            return null;
          }
        },
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title="完整內容"
          open={isReadMoreModalVisible}
          onOk={() => setIsReadMoreModalVisible(false)}
          onCancel={() => setIsReadMoreModalVisible(false)}>
          <Typography>
            <Paragraph>
              <pre>{currentText}</pre>
            </Paragraph>
          </Typography>
        </Modal>
      </>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <CampaignProjectModal
          searchCampaignProjectItem={searchCampaignProjectItem}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
          list={list}
          setList={setList}
          setPickid={setPickid}
          pickid={pickid}
        />
      ),
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <ModifyStatusModal
          isModifyStatusModalVisible={isModifyStatusModalVisible}
          setIsModifyStatusModalVisible={setIsModifyStatusModalVisible}
          modifyStatusForm={modifyStatusForm}
          searchCampaignProjectItem={searchCampaignProjectItem}
        />
      ),
    },
  ];

  return (
    <div>
      <PageHeader functionName={'campaign_project'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

const toExactDate = (date) => {
  const d = new Date(date);
  const hours = d.getHours();

  if (hours >= 22 || hours < 8) {
    // 如果时间在22:00~24:00或00:00~08:00之间
    if (hours >= 22) {
      // 时间在22:00~24:00之间，调到隔天8:00
      d.setDate(d.getDate() + 1); // 增加1天
    }
    // 将时间调整为8:00
    d.setHours(8, 0, 0, 0);
  }

  return d;
};

// 共用的Modal
const CampaignProjectModal = (props) => {
  const {
    searchCampaignProjectItem,
    isModalVisible,
    setIsModalVisible,
    modalForm,
    isNew,
    list,
    setList,
    setPickid,
    pickid,
  } = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const httpHandler = useContext(HttpHandlerContext);
  let keys = [];

  const handleModalSubmit = async () => {
    try {
      let check;
      const formData = new FormData();
      const values = await modalForm.validateFields();
      const time = new Date(values.send_time);
      const hour = time.getHours();
      const timePeriods = {
        1: '早上時段(8:00~12:00)',
        2: '下午時段(12:00~17:00)',
        3: '晚上時段(17:00~22:00)',
      };

      setLoading(true);
      // 根據isNew判斷是新增還是修改
      let requestPromise;
      const msg = isNew ? '新增成功' : '修改成功';

      const channelTitle =
        values.channel == 1
          ? 'push_title'
          : values.channel == 2
          ? 'edm_list_id'
          : 'sms_title';
      const channelContent =
        values.channel == 1
          ? 'push_content'
          : values.channel == 2
          ? ''
          : 'sms_content';

      if (hour >= 8 && hour <= 12) {
        check = 1;
      } else if (hour >= 13 && hour <= 17) {
        check = 2;
      } else if (hour >= 18 && hour <= 22) {
        check = 3;
      }
      // 儲存對應客群的id
      Object.entries(customer_dict).forEach(([key, value]) => {
        if (values.customer.includes(value)) {
          keys.push(Number(key)); // 将找到的键转换为数字并存入keys数组
        }
      });

      // 因為分開儲存進入後端，會因為參數太多被flake8檔住，所以改以字典方式傳送
      const postData = {
        ids: pickid,
        name: values.name,
        project_name: values.project_name,
        channel: values.channel,
        [channelTitle]: values[channelTitle],
        [channelContent]: values[channelContent],
        customer: JSON.stringify(keys),
        exclusion_rule: JSON.stringify(values.exclusion_rule),
        push_score: JSON.stringify(values.push_score),
      };

      if (
        values.description !== null &&
        values.description !== '' &&
        values.description !== undefined
      ) {
        postData['description'] = values.description;
      }
      if (
        values.push_url !== null &&
        values.push_url !== '' &&
        values.push_url !== undefined
      ) {
        postData['push_url'] = values.push_url;
      }

      formData.append('data', JSON.stringify(postData));

      if (values.send_time !== undefined) {
        formData.append(
          'send_time',
          toExactDate(values.send_time).toISOString()
        );
      }
      if (values.test_list !== undefined) {
        formData.append('test_list', values.test_list.file);
      }

      if (isNew === true) {
        // 同時段有審核通過的方案
        if (values.channel !== '2') {
          // ids: 當前方案id => 在判斷同時段是否有重複方案時，計算到自己
          await httpHandler
            .get(
              `/campaign_project/check_time_period?check=${values.send_time.toISOString()}&customers=${JSON.stringify(
                keys
              )}&ids=-1`
            )
            .then(async (response) => {
              if (!response.success) {
                // 推播而且超過5萬筆
                if (response.total >= 50000 && values.channel === '1') {
                  const warning = await window.antdConfirm(
                    `${timePeriods[check]}已有其他排程，請設定其他時間!`
                  );
                  setLoading(false);
                  return;
                }
                // 推播但沒有超過5萬筆或是SMS
                else {
                  const warning = await window.antdConfirm(
                    `${timePeriods[check]}已有其他排程，確定要新增此方案？`
                  );
                  if (!warning) {
                    setLoading(false);
                    return;
                  }
                }
              } else {
                const confirmed = await window.antdConfirm(
                  `確定${isNew ? '新增' : '修改'}方案嗎？`
                );
                if (!confirmed) {
                  setLoading(false);
                  return;
                }
              }
              requestPromise = httpHandler.post(
                '/campaign_project/',
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                  },
                }
              );
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        }
        // 同時段沒有審核通過的方案
        else {
          const confirmed = await window.antdConfirm(
            `確定${isNew ? '新增' : '修改'}方案嗎？`
          );
          if (!confirmed) {
            setLoading(false);
            return;
          }
          requestPromise = httpHandler.post('/campaign_project/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data; charset=UTF-8',
            },
          });
        }
      } else {
        // 同時段有審核通過的方案
        if (values.channel !== '2') {
          // ids: 當前方案id => 在判斷同時段是否有重複方案時，計算到自己
          await httpHandler
            .get(
              `/campaign_project/check_time_period?check=${values.send_time.toISOString()}&customers=${JSON.stringify(
                keys
              )}&ids=${values.id}`
            )
            .then(async (response) => {
              if (!response.success) {
                // 推播而且超過5萬筆
                if (response.total >= 50000 && values.channel === '1') {
                  const warning = await window.antdConfirm(
                    `${timePeriods[check]}已有其他排程，請設定其他時間!`
                  );
                  setLoading(false);
                  return;
                }
                // 推播但沒有超過5萬筆或是SMS
                else {
                  if (values.test_list !== undefined) {
                    const warning = await window.antdConfirm(
                      <div>
                        {timePeriods[check]}已有其他排程，確定要修改此方案？
                        <br />
                        ⚠️注意：上傳的名單將替換現有名單!
                      </div>
                    );
                    if (!warning) {
                      setLoading(false);
                      return;
                    }
                  } else {
                    const warning = await window.antdConfirm(
                      <div>
                        {timePeriods[check]}已有其他排程，確定要修改此方案？
                      </div>
                    );
                    if (!warning) {
                      setLoading(false);
                      return;
                    }
                  }
                }
              }
              // 同時段沒有審核通過的方案
              else {
                if (values.test_list !== undefined) {
                  const confirmed = await window.antdConfirm(
                    `⚠️注意：上傳的名單將替換現有名單!`
                  );
                  if (!confirmed) {
                    setLoading(false);
                    return;
                  }
                } else {
                  const confirmed = await window.antdConfirm(`確認修改方案？`);
                  if (!confirmed) {
                    setLoading(false);
                    return;
                  }
                }
              }
              requestPromise = httpHandler.post(
                `/campaign_project/${values.id}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data; charset=UTF-8',
                  },
                }
              );
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        }
        // EDM
        else {
          if (values.test_list !== undefined) {
            const confirmed = await window.antdConfirm(
              `⚠️注意：上傳的名單將替換現有名單!`
            );
            if (!confirmed) {
              setLoading(false);
              return;
            }
          } else {
            const confirmed = await window.antdConfirm(`確認修改方案？`);
            if (!confirmed) {
              setLoading(false);
              return;
            }
          }
          requestPromise = httpHandler.post(
            `/campaign_project/${values.id}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data; charset=UTF-8',
              },
            }
          );
        }
      }

      requestPromise
        .then((response) => {
          if (response.success) {
            window.antdNotify('成功', msg, 'success');
            setIsModalVisible(false);
            searchCampaignProjectItem();
            modalForm.resetFields();
            setLoading(false);
            keys = [];
          } else {
            setLoading(false);
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .finally(async () => {
          setFileList([]);
          setList([]);
          setLoading(false);
          setIsModalVisible(false);
          modalForm.setFieldsValue({ ['customer']: [] });
        });
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = async () => {
    modalForm.resetFields();
    setIsModalVisible(false);
    setList([]);
    setPickid(-1);
    keys = [];
  };
  return (
    <Modal
      title={isNew ? '新增方案' : '修改方案'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}
      okButtonProps={{ loading: loading }}>
      <ModalForm
        modalForm={modalForm}
        isNew={isNew}
        fileList={fileList}
        setFileList={setFileList}
        setList={setList}
        list={list}
        setPickid={setPickid}
        pickid={pickid}
      />
    </Modal>
  );
};
// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm, fileList, setList, list, setPickid, pickid } = props;
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [name, setName] = useState('');
  const [items, setItems] = useState(customer_list);
  const [scores, setScores] = useState(scoreMap);
  const inputRef = useRef(null);
  const sendRef = useRef('立即發送');
  const httpHandler = useContext(HttpHandlerContext);
  const Options = ['立即發送', '預約發送'];

  // 設定發送時間月曆格式，年月日＋時分
  const buddhistLocale = {
    ...en,
    lang: {
      ...en.lang,
      fieldDateFormat: 'YYYY-MM-DD',
      fieldDateTimeFormat: 'YYYY-MM-DD HH:mm',
      yearFormat: 'YYYY',
      cellYearFormat: 'YYYY',
    },
  };

  // 動態改變時間，立即發送=>預設當天時間、預約發送=>預設7天後
  const onChange1 = ({ target: { value } }) => {
    sendRef.current = value;
    if (sendRef.current == '預約發送') {
      modalForm.setFieldsValue({ ['send_time']: dayjs().add(7, 'day') });
    } else {
      modalForm.setFieldsValue({ ['send_time']: dayjs() });
    }
  };

  // 動態取得當前選擇的活動所包含的客群
  const campaignChange = async (campaign) => {
    inputRef.current = campaign;
    setPickid(campaign);
    customer_list = [];
    await httpHandler
      .get(
        `/target_customer/list?ids=${campaign}&name=${
          eventMap[inputRef.current]
        }&page=1&page_size=10&is_all=false&fuzzy=false`
      )
      .then((response) => {
        if (response.success) {
          customer_list = [];
          customer_dict = {};
          response.data.forEach((element) => {
            customer_list.push(element.customer_name);
            customer_dict[element.id] = element.customer_name;
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setList(customer_list);
    modalForm.setFieldsValue({ ['customer']: [] });
  };

  // 動態取得測試人員資料
  const handleChange = (file) => {
    fileList.current = file;
    if (fileList.current.fileList.length === 0) {
      fileList.current = null;
    }
  };

  // 只取得檔案資料，不然會報錯
  const beforeUpload = (file) => {
    file = file['file']['originFileObj'];
    return file;
  };
  const handleChannelChange = (value) => {
    setSelectedChannel(value);
  };

  const getLabel = () => {
    switch (selectedChannel) {
      case '1':
        return '推播標題';
      case '2':
        return 'EDM編號';
      default:
        return '推播標題'; // 默认标签
    }
  };

  const getContent = () => {
    switch (selectedChannel) {
      case '1':
        return '推播內容';
      case '3':
        return 'SMS內容';
      default:
        return '推播內容'; // 默认标签
    }
  };

  const getName = () => {
    switch (selectedChannel) {
      case '1':
        return 'push_title';
      case '2':
        return 'edm_list_id';
      default:
        return 'push_title'; // 默认标签
    }
  };

  const getContentName = () => {
    switch (selectedChannel) {
      case '1':
        return 'push_content';
      case '3':
        return 'sms_content';
      default:
        return 'push_content'; // 默认标签
    }
  };

  const getValidationMessage = () => {
    switch (selectedChannel) {
      case '1':
        return '請輸入推播標題!';
      case '2':
        return '請輸入EDM編號!';
      default:
        return '請輸入推播標題'; // 默认验证消息
    }
  };

  const getContentMessage = () => {
    switch (selectedChannel) {
      case '1':
        return '請輸入推播內容!';
      case '3':
        return '請輸入SMS內容!';
      default:
        return '請輸入推播內容'; // 默认验证消息
    }
  };

  return (
    <Form
      form={modalForm}
      initialValues={{
        name: undefined,
        send_time: dayjs(),
        send_type: '立即發送',
        channel: '1',
        exclusion_rule: [
          'exception',
          'count',
          'shortblock',
          'scoreblock',
          'mobilblock',
        ],
        push_score: scores,
      }}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="活動名稱"
        name="name"
        rules={[{ required: true, message: '請選擇活動名稱!' }]}
        style={{ marginBottom: 20 }}>
        <Select
          showSearch
          allowClear
          onChange={campaignChange}
          dropdownStyle={{ height: '166px', overflow: 'auto' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {Object.keys(eventMap)
            .slice()
            .reverse()
            .map((key) => {
              if (eventStatus[key] !== 98 && eventStatus[key] !== 99) {
                return (
                  <Select.Option key={key} value={key}>
                    {eventMap[key]}
                  </Select.Option>
                );
              }
              return null;
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="方案名稱"
        name="project_name"
        rules={[{ required: true, message: '請選擇方案名稱!' }]}
        style={{ marginBottom: 20 }}>
        <Input />
      </Form.Item>
      <Form.Item
        label="方案描述"
        name="description"
        style={{ marginBottom: 20 }}
        labelCol={{ span: 4 }}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="行銷通路"
        name="channel"
        rules={[{ required: true, message: '請選擇行銷通路!' }]}
        style={{ marginBottom: 15 }}>
        <Select defaultValue={'1'} onChange={handleChannelChange}>
          {Object.keys(channelMap)
            .filter((key) => key !== '0')
            .map((key) => (
              <Select.Option key={key} value={key}>
                {channelMap[key]}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.channel !== currentValues.channel
        }>
        {({ getFieldValue }) => {
          const currentChannel = getFieldValue('channel');
          if (currentChannel !== selectedChannel) {
            setSelectedChannel(currentChannel);
          }
          if (currentChannel === '1') {
            return (
              <Form.Item
                label={getLabel()}
                name={getName()}
                rules={[
                  { required: true, message: getValidationMessage() },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 250) {
                        return Promise.reject(new Error(`字數不能超過250字!`));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{ marginBottom: 35 }}>
                <Input />
              </Form.Item>
            );
          } else if (currentChannel === '2') {
            return (
              <Form.Item
                label={getLabel()}
                name={getName()}
                rules={[{ required: true, message: getValidationMessage() }]}
                style={{ marginBottom: 35, marginTop: 15 }}>
                <Input />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.channel !== currentValues.channel
        }
        style={{ marginTop: -45 }}>
        {({ getFieldValue }) => {
          const currentChannel = getFieldValue('channel');
          if (currentChannel !== selectedChannel) {
            setSelectedChannel(currentChannel);
          }
          if (currentChannel === '1') {
            return (
              <Form.Item
                label={getContent()}
                name={getContentName()}
                rules={[
                  { required: true, message: getContentMessage() },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 750) {
                        return Promise.reject(new Error(`字數不能超過750字!`));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{ marginBottom: -5 }}>
                <Input.TextArea />
              </Form.Item>
            );
          } else if (currentChannel === '3') {
            return (
              <Form.Item
                label={getContent()}
                name={getContentName()}
                rules={[
                  { required: true, message: getContentMessage() },
                  {
                    validator: (_, value) => {
                      // 限制SMS內容輸入長度70
                      if (value && value.length > 70) {
                        return Promise.reject(new Error(`字數不能超過70字!`));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{ marginBottom: -15 }}>
                <Input.TextArea />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.channel !== currentValues.channel
        }>
        {({ getFieldValue }) => {
          const currentChannel = getFieldValue('channel');
          if (currentChannel !== selectedChannel) {
            setSelectedChannel(currentChannel);
          }
          if (currentChannel === '1') {
            return (
              <Form.Item
                label={'推播網址'}
                name={'push_url'}
                style={{ marginBottom: 40 }}
                labelCol={{ span: 4 }}>
                <Input />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.channel !== currentValues.channel ||
          currentValues.send_type !== sendRef.current
        }
        style={{ marginBottom: -100, marginTop: -50 }}>
        {({ getFieldValue }) => {
          const currentChannel = getFieldValue('channel');
          sendRef.current = getFieldValue('send_type');

          if (currentChannel !== selectedChannel) {
            setSelectedChannel(currentChannel);
          }
          return (
            currentChannel !== '2' && (
              <>
                <Form.Item
                  label="發送方式"
                  name="send_type"
                  rules={[{ required: true, message: '請選取發送方式' }]}
                  style={{ marginBottom: 10 }}>
                  <Radio.Group
                    options={Options}
                    onChange={onChange1}
                    value={sendRef.current}
                  />
                </Form.Item>
                <Form.Item
                  label="發送日期"
                  name="send_time"
                  rules={[{ required: true, message: '請選取發送日期' }]}
                  style={{ marginBottom: 95 }}>
                  <DatePicker
                    disabled={sendRef.current == '立即發送'}
                    disabledDate={(current) =>
                      current && current.isBefore(dayjs().startOf('day'))
                    }
                    showTime
                    locale={buddhistLocale}
                    disabledTime={() => ({
                      disabledHours: () => {
                        const hours = [];

                        for (let i = 0; i < 24; i++) {
                          if (i < 8 || i > 22) {
                            hours.push(i);
                          }
                        }
                        return hours;
                      },
                      disabledMinutes: (selectedHour) => {
                        if (selectedHour === 22) {
                          // 22点时，只允许选择00分钟
                          return [...Array(60).keys()].filter(
                            (min) => min !== 0
                          );
                        }
                        return [];
                      },
                    })}
                  />
                </Form.Item>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item
        label="客群設定"
        name="customer"
        rules={[{ required: true, message: '請設定客群' }]}
        style={{ marginBottom: 15, marginTop: 20 }}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          options={list.map((item) => ({
            label: item,
            value: item,
          }))}></Select>
      </Form.Item>
      <Form.Item
        label="上傳測試人員"
        name="test_list"
        style={{ marginBottom: 15 }}>
        <Upload
          accept=".csv"
          beforeUpload={beforeUpload}
          onChange={handleChange}
          maxCount={1}>
          <Button icon={<PlusOutlined />}>上傳</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="推播積分設定"
        name="push_score"
        rules={[{ required: true, message: '請選取發送積分區間' }]}
        style={{ width: 400, marginBottom: 15 }}>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          options={scores.map((score) => ({
            label: score,
            value: score,
          }))}></Select>
      </Form.Item>
      <Form.Item
        label="排除名單設定"
        name="exclusion_rule"
        style={{ marginBottom: 10 }}>
        <Checkbox.Group>
          <Checkbox value="exception">駕照查驗結果異常</Checkbox>
          <Checkbox value="count">超過接觸次數</Checkbox>
          <Checkbox disabled value="shortblock">
            短租黑名單
          </Checkbox>
          <Checkbox disabled value="scoreblock">
            會員積分黑名單
          </Checkbox>
          <Checkbox disabled value="mobilblock">
            手機黑名單
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </Form>
  );
};
// 申請簽核、撤銷組件
const ModifyStatusModal = (props) => {
  const httpHandler = useContext(HttpHandlerContext);
  const {
    isModifyStatusModalVisible,
    setIsModifyStatusModalVisible,
    modifyStatusForm,
    searchCampaignProjectItem,
  } = props;
  const [loading, setLoading] = useState(false);
  const handleModifyStatusModalCancel = () => {
    modifyStatusForm.resetFields();
    setIsModifyStatusModalVisible(false);
  };
  const handleModifyStatusModalSubmit = async () => {
    // 驗證表單
    const values = await modifyStatusForm
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((error) => {
        console.error('error: ', error);
      });
    if (!values) return;
    // 送出表單
    console.log('values: ', values);
    setLoading(true);
    httpHandler
      .put(`/campaign_project/modify_status/${values.projectId}`, {
        origin_status: parseInt(values.originStatus),
        modify_status: parseInt(values.modifyStatus),
      })
      .then((response) => {
        if (response.success) {
          window.antdNotify('成功', response.message, 'success');
          searchCampaignProjectItem();
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .finally(() => {
        setLoading(false);
        setIsModifyStatusModalVisible(false);
        modifyStatusForm.resetFields();
      });
  };

  return (
    <Modal
      icon={<QuestionCircleOutlined />}
      title={
        modifyStatusForm.getFieldValue('modifyStatus') == 2
          ? '是否申請簽核？'
          : '是否撤銷？'
      }
      open={isModifyStatusModalVisible}
      onCancel={handleModifyStatusModalCancel}
      footer={[
        <Button key="back" onClick={handleModifyStatusModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleModifyStatusModalSubmit(2)} // 2: 送審中
          loading={loading}>
          確定
        </Button>,
      ]}>
      <Form form={modifyStatusForm}>
        {/* 方案id */}
        <Form.Item name="projectId" hidden>
          <Input />
        </Form.Item>
        {/* 原始狀態 */}
        <Form.Item name="originStatus" hidden>
          <Input />
        </Form.Item>
        {/* 修改狀態 */}
        <Form.Item name="modifyStatus" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

// 驗證props
CampaignProjectModal.propTypes = {
  searchCampaignProjectItem: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
};
ModalForm.propTypes = {
  modalForm: PropTypes.object,
};
ModifyStatusModal.propTypes = {
  isModifyStatusModalVisible: PropTypes.bool,
  setIsModifyStatusModalVisible: PropTypes.func,
  modifyStatusForm: PropTypes.object,
  searchCampaignProjectItem: PropTypes.func,
};
export default CampaignProject;
